// Required imports
import React from 'react'
import Image from 'react-bootstrap/Image'

// Custom file imports
import NavLogoSrc from '../../static/images/new_sloth_500.png'
import GameLogoZombieSrc from '../../static/images/another_zombie_game_logo_white_zombie.png'
import GameLogoSrc from '../../static/images/another_zombie_game_logo_white.png'
import GuessnLogoSrc from '../../static/images/guessn_logo.png'
import StudioLogoSrc from '../../static/images/novasloth_azonix_logo.png'
import UnityLogoSrc from '../../static/images/unity_logo.png'
import ChecklistLogoSrc from '../../static/images/checklist_logo.png'
import MyLink from '../components/my-link.js'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N S
/// //////////////////////////////////////////////////////////

export const NavLogo = ({ className }) => (
  <Image className={`logo nav ${className}`} src={NavLogoSrc} />
)

export const GameLogo = ({ className, size, link, zombie }) => (
  zombie
    ? <MyLink link={link} href='/projects'>
        <Image className={`logo ${size} ${className}`} src={GameLogoZombieSrc} />
      </MyLink>
    : <MyLink link={link} href='/projects'>
        <Image className={`logo ${size} ${className}`} src={GameLogoSrc} />
      </MyLink>
)

export const GuessnLogo = ({ className, size }) => (
  <Image className={`logo ${size} ${className}`} src={GuessnLogoSrc} />
)

export const ChecklistLogo = ({ className, size, link }) => (
  <MyLink link tab href=''>
    <Image className={`logo ${size} ${className}`} src={ChecklistLogoSrc} />
  </MyLink>
)

export const StudioLogo = ({ className, size }) => (
  <Image className={`logo ${size} ${className}`} src={StudioLogoSrc} />
)

export const UnityLogo = ({ className, link }) => (
  <MyLink link tab href='https://unity.com/'>
    <Image className={`logo lg ${className}`} src={UnityLogoSrc} />
  </MyLink>
)

export const SlothLogo = ({ className, size }) => (
  <Image className={`logo ${size}`} src={NavLogoSrc} />
)

// const MyLink = ({ children, link, href, tab }) => (
//   link
//     ? (<a href={href} target={tab ? '_blank' : ''} rel='noreferrer'>{children}</a>)
//     : (<>{children}</>)
// )