// Required imports
import React, { useState, useEffect } from 'react'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

export default function RenderOnClient ({ children }) {

  const [isClient, setClient] = useState(false)
  const key = isClient ? 'client' : 'server'

  useEffect(() => {
    setClient(true)
  }, [])

  if (!isClient) { return null }
  return (
    <React.Fragment key={key}>
      {children}
    </React.Fragment>
  )
}