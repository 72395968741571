// Required imports
import React from 'react'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

const MyLink = ({ children, link, href, tab }) => (
  link
    ? (<a href={href} target={tab ? '_blank' : ''} rel='noreferrer'>{children}</a>)
    : (<>{children}</>)
)

export default MyLink