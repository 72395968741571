// Required imports
import React from 'react'
import Navigation from './navigation.js'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

export default function NavWrapper (props) {
  return (
    <>
      <Navigation {...props} />
      {props.children}
    </>
  )
}