// Required imports
import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

// Custom file imports
import RenderOnClient from '../components/render-on-client.js'
import { NavLogo } from './logos.js'

/// //////////////////////////////////////////////////////////
// G L O B A L S
/// //////////////////////////////////////////////////////////

const ROW_CLASS = 'justify-content-md-center'
const NAVBAR_CLASS = 'sloth-font nav'
const COL_CLASS = { START: 'flex start', CENTER: 'flex center', END: 'flex end' }

const NAVITEM_BSPREFIX = 'nav-item custom-nav-item'
const NAVLINK_BSPREFIX = 'nav-link custom-nav-link'

const UNDERLINE_CLASS = 'underline'

const HIDE_SM = 'd-none d-xl-block d-md-block d-sm-block'

const HREF_HOME = '/'
const HREF_BLOG = '/blog'
const HREF_GAMES = '/#games'

/// //////////////////////////////////////////////////////////
// C O M P O N E N T   D E F I N I T I O N
/// //////////////////////////////////////////////////////////

export default function Navigation ({ location }) {

  const path = location.pathname

  return (
    <RenderOnClient>
      <Container fluid>
        <Row className={ROW_CLASS}>
          <Navbar className={NAVBAR_CLASS} expand='sm'>
            <Col className={COL_CLASS.START}>
              <Navbar.Brand href={HREF_HOME} className={HIDE_SM}>
                <NavLogo />
              </Navbar.Brand>
            </Col>
            <Col className={COL_CLASS.CENTER}>
              <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
              <Navbar.Collapse id='responsive-navbar-nav'>
                <Nav>
                  <Nav.Item bsPrefix={NAVITEM_BSPREFIX}>
                    <Nav.Link
                      href={HREF_HOME}
                      bsPrefix={NAVLINK_BSPREFIX}
                      className={path === HREF_HOME ? UNDERLINE_CLASS : ''}
                    >
                      HOME
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item bsPrefix={NAVITEM_BSPREFIX}>
                    <Nav.Link
                      href={HREF_GAMES}
                      bsPrefix={NAVLINK_BSPREFIX}
                    >
                      GAMES
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item bsPrefix={NAVITEM_BSPREFIX}>
                    <Nav.Link
                      href={HREF_BLOG}
                      bsPrefix={NAVLINK_BSPREFIX}
                      className={path === HREF_BLOG ? UNDERLINE_CLASS : '' }
                    >
                      BLOG
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Col>
            <Col className={COL_CLASS.END} />
          </Navbar>
        </Row>
      </Container>
    </RenderOnClient>
  )
}